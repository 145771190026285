import revive_payload_client_ZoLeJvt8hC from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._yyn7nezw5gj3cq265ps4e52isy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gwkRYqq0cz from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._yyn7nezw5gj3cq265ps4e52isy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QQpAOaOCp9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._yyn7nezw5gj3cq265ps4e52isy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_L7acTkzpWI from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._yyn7nezw5gj3cq265ps4e52isy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6jeO5xSjKr from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._yyn7nezw5gj3cq265ps4e52isy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_g9bvbbGaUt from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._yyn7nezw5gj3cq265ps4e52isy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_XYM05CdL5R from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._yyn7nezw5gj3cq265ps4e52isy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/platform-admin/.nuxt/components.plugin.mjs";
import prefetch_client_ZeUM45mier from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._yyn7nezw5gj3cq265ps4e52isy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_9gq5bINlCQ from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import tooltip_3yU6vxfDCQ from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.2.0_magicast@0.3.5_nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.1_327shkonum2tcn5bnjheawqltm/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/platform-admin/plugins/bugsnag.ts";
export default [
  revive_payload_client_ZoLeJvt8hC,
  unhead_gwkRYqq0cz,
  router_QQpAOaOCp9,
  payload_client_L7acTkzpWI,
  navigation_repaint_client_6jeO5xSjKr,
  check_outdated_build_client_g9bvbbGaUt,
  chunk_reload_client_XYM05CdL5R,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZeUM45mier,
  plugin_9gq5bINlCQ,
  tooltip_3yU6vxfDCQ,
  bugsnag_tkMQfOjcVZ
]